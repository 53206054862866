import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <h1>
          <span>404</span>Page not found :(
        </h1>
      </div>
    );
  }
}
