import React from 'react';

export const Sandbox = () => {

  return (
    <div className="sandbox">
      <h1>Sandbox</h1>
    </div>
  );
}

export default Sandbox;